.about {
  width: 75%;
}

.tabs {
  border-radius: 10px;
  background-color: var(--secondary);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

#tabs-tab-aboutme,
#tabs-tab-myskills,
#tabs-tab-myhobbies,
.tab-content p,
.tab-content li {
  color: var(--header);
}

#tabs-tab-aboutme:hover,
#tabs-tab-myskills:hover,
#tabs-tab-myhobbies:hover {
  color: rgba(3, 48, 71, 0.7);
}

@media (max-width: 767px) {
  .about {
    width: 100%;
  }
}
