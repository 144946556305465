* {
  --primary: #219ebc;
  --secondary: #fffdec;
  --header: #033047;
  --text: #e0e0e0;
  --button: #ffb805;
  --link-color: rgba(255, 255, 255, 0.75);
  --link-hover-color: rgba(255, 255, 255, 0.85);
  --link-active-color: rgba(255, 255, 255, 1);

  font-family: "Roboto", sans-serif;
  font-size: large;
  color: var(--text);

  --bs-link-color: var(var(--header)) !important;
  --bs-modal-bg: var(var(--secondary));

  --bs-navbar-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.95);

  --bs-nav-tabs-border-color: var(--header) !important;
  --bs-nav-tabs-link-hover-border-color: var(--header) !important;
  --bs-nav-tabs-link-active-color: var(--header);
  --bs-nav-tabs-link-active-bg: var(--button);
  --bs-nav-tabs-link-active-border-color: var(--header);
  overflow-x: visible;
}

.navbar-nav a {
  color: var(--link-color);
  display: inline-block;
  position: relative;
}

.navbar-nav a:hover {
  color: var(--link-hover-color);
  cursor: pointer;
}

.navbar-nav a.active {
  color: var(--link-active-color);
}

.navbar-nav a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--button);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar-nav a.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

body {
  background-color: var(--primary) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Header {
  background-color: var(--header);
}

.offcanvas-md {
  background-color: var(--header) !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

section {
  min-height: 80vh;
  display: flex;
}
.scroll-to-top {
  cursor: pointer;
}
